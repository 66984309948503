.Toolbar {
	height: 75px;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #000000;
	border-bottom: 3px groove #ff0000;
	/* border: 5px solid red; */
	/* border-style: outset; */
	/* background-color: 050505; */
	display: flex;
	justify-content: space-between;
	padding: 0 20px;
	box-sizing: border-box;
	z-index: 90;
}

.Toolbar nav {
	height: 100%;
}

.Logo {
	height: 90%;
}

@media (max-width: 499px) {
	.DesktopOnly {
		display: none;
	}
}
