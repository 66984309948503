.NavigationItem {
	margin: 0 20px;
	display: flex;
	width: auto;
	align-items: center;
	font-size: 1.5em;
}

.NavigationItem a {
	color: red;
	height: 100%;
	padding: 16px 10px;
	border-bottom: 4px solid transparent;
	text-decoration: none;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
	/* font-size: 1.1em; */
	color: #39ff14;
}
