@font-face {
	font-family: Exan-Regular;
	src: url('./assets/Fonts/Exan-Regular.ttf');
}

body {
	margin: 0;
	padding: 0;
	font-family: "Open sans", sans-serif;
	background-color: rgb(14, 13, 13);
}

body h1 {
	color: red;
	font-family: Exan-Regular, sans-serif;
	letter-spacing: 4px;
}

::-webkit-scrollbar {
	display: none;
}
