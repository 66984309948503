.Contact {
	margin-top: 150px;
}
.ContactContainer {
	max-width: 670px;
	width: 70%;
	margin: auto;
	background: #272121;
	border: 1px solid red;
	box-shadow: 0px 0px 15px 2.5px #ff0000;
}

.ContactContainer h1 {
	text-align: center;
	margin-top: 20px;
}

.ContactContainer form {
	width: 80%;
	margin: 30px auto;
}

.ContactContainer .btn {
	margin-top: 200px;
}

.ContactContainer label {
	color: whitesmoke;
	display: block;
	width: 100%;
	padding: 10px 0;
	font-size: large;
}

.ContactContainer input {
	height: 30px;
	width: 100%;
	border-radius: 10px;
	font-size: large;
	margin-bottom: 25px;
}

.ContactContainer textarea {
	width: 100%;
	border-radius: 10px;
	font-size: large;
}

.btnPosition {
	width: 100%;
	text-align: center;
}
