.DrawerToggle {
	width: 40px;
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: space-evenly;
	align-items: center;
	padding: 10px 0;
	box-sizing: border-box;
	cursor: pointer;
}

.DrawerToggle div {
	width: 90%;
	height: 4px;
	background-color: #ff0000;
}

@media (min-width: 500px) {
	.DrawerToggle {
		display: none;
	}
}
