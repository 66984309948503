.NavigationItems {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-flow: row;
	align-items: center;
	height: 100%;
}

@media (max-width: 499px) {
	.NavigationItems {
		flex-direction: column;
	}
	ul {
		width: 100%;
	}
}
