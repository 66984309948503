.Button {
	background-color: transparent;
	border: none;
	color: white;
	outline: none;
	cursor: pointer;
	font: inherit;
	padding: 15px;
	margin: 15px;
	font-weight: bold;
	font-size: x-large;
}

.Button:disabled {
	color: #ccc;
	cursor: not-allowed;
}

.SuccessNeon {
	border: 2px solid #39ff14;
	color: #39ff14;
}

.DangerNeon {
	border: 2px solid red;
	color: red;
}
