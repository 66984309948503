p {
	margin: 16px;
	color: whitesmoke;
}

h1 {
	margin: 0;
	padding: 0;
	color: white;
}

.LandingPagePart1 {
	height: 100vh;
	display: flex;
	justify-content: flex-start;
	background: #010101;
	flex-direction: column;
}

.LogoDiv {
	height: 400px;
	width: auto;
	display: inline-block;
	margin-top: 125px;
}

.LogoText {
	height: 175px;
	width: auto;
	display: inline-block;
	margin-top: 5px;
}

.LandingPagePart2Grid {
	display: grid;
	height: 100vh;
	grid-template-columns: 100px 1fr 1fr 1fr 1fr 1fr 100px;
	grid-template-rows: 150px 1fr 1fr 1fr 150px;
	background: #171313;
	background: -webkit-linear-gradient(bottom, #171313, #010101);
	background: -moz-linear-gradient(bottom, #171313, #010101);
	background: linear-gradient(to top, #171313, #010101);
}

.TsDcDiv {
	margin: 25px;
	height: 75px;
	width: auto;
	position: relative;
	overflow: hidden;
}

.TsDcDiv span {
	position: absolute;
	display: block;
}

.TsDcDiv:hover {
	cursor: pointer;
}

.TsDcTitle {
	grid-row: 2 / 3;
	grid-column: 3 / 6;
	text-align: center;
}

.DcContent {
	max-width: 300px;
	min-width: 250px;
	grid-row: 3 / 5;
	grid-column: 3 / 4;
	justify-content: space-between;
	flex-direction: column;
	text-align: center;
	display: flex;
	background-color: #272121;
	margin: 0 auto;
	box-shadow: 0px 0px 15px 2.5px #ff0000;
	z-index: 5;
}
.TsContent {
	max-width: 300px;
	min-width: 250px;
	background-color: #272121;
	grid-row: 3 / 5;
	grid-column: 5 / 6;
	justify-content: space-between;
	flex-direction: column;
	text-align: center;
	display: flex;
	background-color: #272121;
	margin: 0 auto;
	box-shadow: 0px 0px 15px 2.5px #ff0000;
}

.NeonEffect {
	max-width: 300px;
	min-width: 250px;
	background-color: #272121;
	border-radius: 10%;
	grid-row: 4 / 6;
	grid-column: 5 / 6;
}

.LandingPagePart3 {
	display: block;
	background: #171313;
}

.LpIconsDiv {
	height: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	text-align: center;
	background: #171313;
}

.Footer {
	grid-row: 4 / 5;
	grid-column: 1 / 4;
	display: flex;
	justify-content: center;
	background: #171313;
}

/* SMALL SCREEN SIZE */
@media (max-width: 640px) {
	.LandingPagePart2 {
		flex-wrap: wrap;
	}
}

@media (max-width: 840px) {
	.LogoDiv {
		height: 300px;
	}
	.LogoText {
		height: 130px;
	}
	h1 {
		font-size: x-large;
	}
	.TsDcTitle {
		grid-row: 1 / 2;
	}
	.TsContent {
		grid-column: 4 / 5;
		grid-row: 2 / 4;
		height: 250px;
	}
	.DcContent {
		grid-column: 4 / 5;
		grid-row: 4 / 6;
		height: 250px;
	}
}

@media (max-width: 499px) {
	.LandingPagePart2Grid {
		height: auto;
		padding-bottom: 30px;
		background-color: #272121;
	}

	.LandingPagePart2Grid p {
		font-size: smaller;
	}

	.LogoDiv {
		height: 200px;
		margin-top: 32vh;
	}
	.LogoText {
		height: 90px;
	}
	h1 {
		font-size: large;
	}
	.LandingPagePart2Grid {
		display: flex;
		flex-direction: column;
	}

	.TsDcTitle {
		margin: 30px 20px;
	}
	.DcContent {
		margin-top: 30px;
		width: 65vw;
	}
	.TsContent {
		margin-top: 50px;
		width: 65vw;
	}
}

/* VOICE CHANNELS ANIMATION */
.TsDcDiv span:nth-child(1) {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 3px;
	background: linear-gradient(90deg, transparent, #ff0000);
}

.TsDcDiv:hover span:nth-child(1) {
	display: inline;
	/* left: 100%;
	transition: 0.6s; */
	animation: animate1 1.0s linear infinite;
}

.TsDcDiv span:nth-child(2) {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	width: 3px;
	height: 100%;
	background: linear-gradient(180deg, transparent, #ff0000);
}

.TsDcDiv:hover span:nth-child(2) {
	display: inline;
	/* top: 100%;
	transition: 0.6s;
	transition-delay: 0.15s; */
	animation: animate2 1.0s linear infinite;
	animation-delay: 0.50s;
}

.TsDcDiv span:nth-child(3) {
	display: none;
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 3px;
	background: linear-gradient(270deg, transparent, #ff0000);
}

.TsDcDiv:hover span:nth-child(3) {
	display: inline;
	/* right: 100%;
	transition: 0.6s;
	transition-delay: 0.3s; */
	animation: animate3 1.0s linear infinite;
}

.TsDcDiv span:nth-child(4) {
	display: none;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 3px;
	height: 100%;
	background: linear-gradient(360deg, transparent, #ff0000);
}

.TsDcDiv:hover span:nth-child(4) {
	display: inline;
	animation: animate4 1.0s linear infinite;
	animation-delay: 0.50s;
}

@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}
@keyframes animate2 {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(100%);
	}
}
@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}
@keyframes animate4 {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(-100%);
	}
}
