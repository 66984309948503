/* .Footer {
	height: 65px;
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: blue;
	display: flex;
	justify-content: space-evenly;
	padding: 0 20px;
	box-sizing: border-box;
	z-index: 90;
} */

.Footer {
	padding-bottom: 20px;
	color: white;
	font-weight: lighter;
}
