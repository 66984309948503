.AboutText p {
	margin: 20px;
	text-align: justify;
}

.AboutText h2 {
	color: #ff0000;
	text-align: center;
}
.MainContainer {
	margin-top: 100px;
}

.AboutContainer1 {
	margin: 20px;
	display: flex;
	justify-content: center;
	flex-direction: row;
}

.AboutContainer2 {
	margin: 20px;
	display: flex;
	justify-content: center;
	flex-direction: row-reverse;
}

.AboutContainer1 img {
	height: 160%;
}

.AboutContainer2 img {
	height: 140%;
}

.AboutText {
	min-width: 35%;
	max-width: 615px;
	min-height: 200px;
	max-height: 300px;
	background-color: #272121;
}

.AboutImg {
	min-width: 35%;
	max-width: 615px;
	min-height: 200px;
	max-height: 250px;
	overflow: hidden;
}

/* MEDIUM SCREEN SIZE */
@media (max-width: 830px) {
	.AboutContainer1 {
		flex-direction: column;
		align-items: center;
	}
	.AboutContainer1 img {
		height: 400px;
	}

	.AboutContainer2 img {
		height: 350px;
	}

	.AboutContainer2 {
		flex-direction: column;
		align-items: center;
	}
	.AboutText {
		margin-top: 0;
		width: 90%;
	}
	.AboutImg {
		margin-top: 0;
		width: 90%;
	}
}
