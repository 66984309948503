/* .IconBg {
	display: flex;
	box-sizing: content-box;
	display: block;
	height: 60px;
	width: 60px;
	align-items: center;

	background-color: wheat;
	margin: 0 15px;
	border-radius: 50%;
	background: #171515;
	text-align: center;
	cursor: pointer;
}

.Icon {
	color: #666;
	font-size: 30px;
	line-height: 60px;
	text-align: center;
} */

.SocialsUl {
	display: flex;
	list-style: none;
	text-align: center;
	justify-content: space-evenly;
	padding: 0;
	background: #171313;
	/* z-index: 0; */
}

.SocialsUl li {
	position: relative;
	display: block;
	color: rgb(148, 148, 148);
	font-size: 30px;
	height: 60px;
	width: 60px;
	background: #171515;
	line-height: 60px;
	border-radius: 50%;
	margin: 0 15px;
	cursor: pointer;
	transition: 0.5s;
}

.SocialsUl li::before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	background: #ff4d00;
	height: 100%;
	width: 100%;
	border-radius: 50%;
	z-index: -1;
	transform: scale(0.9);
	transition: 0.5s;
}

.SocialsUl li:hover:before {
	filter: blur(4px);
	transform: scale(1.2);
	box-shadow: 0 0 25px #ff4d00;
}

.SocialsUl li:hover {
	color: #ffa502;
	box-shadow: 0 0 25px #ff4d00;
	text-shadow: 0 0 25px #ff4d00;
}

@media (max-width: 499px) {
	.SocialsUl {
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 70px;
	}
	.SocialsUl li {
		font-size: 20px;
		height: 40px;
		width: 40px;
		line-height: 40px;
	}
}
