.MpgText {
	padding: 6px;
	height: 100%;
	box-sizing: border-box;
	text-align: center;
}

.MpgText img {
	height: 100%;
}
